import { useState, useEffect } from 'react';
import { getBlogPosts, getPublishedBlogPosts, BlogPost } from '../services/blog';
import { useAuth } from '../contexts/AuthContext';
import { isAdmin } from '../lib/auth';

export const useBlogPosts = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();
  const userIsAdmin = user && isAdmin(user);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // If user is admin, get all posts. Otherwise, only get published posts
        const data = userIsAdmin 
          ? await getBlogPosts()
          : await getPublishedBlogPosts();
          
        setPosts(data);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch blog posts'));
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [userIsAdmin]);

  return { posts, loading, error };
};