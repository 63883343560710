import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  RefreshCw,
  FileDown,
  Clock,
  Bell,
  FileSearch,
  ArrowUp,
  ArrowDown,
  ExternalLink,
  Loader2
} from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import SEO from '../../components/SEO';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const SEODashboard = () => {
  const [dateRange, setDateRange] = useState('30d');
  const [loading, setLoading] = useState(false);

  const refreshData = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  };

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Organic Traffic',
        data: [1200, 1900, 3000, 5000, 4800, 6300],
        borderColor: 'rgb(20, 184, 166)',
        backgroundColor: 'rgba(20, 184, 166, 0.5)',
      },
    ],
  };

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="SEO Dashboard"
        description="Monitor and analyze your website's search engine performance"
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-bold">SEO Dashboard</h1>
            <div className="flex items-center space-x-4">
              <select
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
                className="border border-gray-300 rounded-md px-4 py-2 focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              >
                <option value="7d">Last 7 days</option>
                <option value="30d">Last 30 days</option>
                <option value="90d">Last 90 days</option>
              </select>
              <button
                onClick={refreshData}
                disabled={loading}
                className="btn btn-secondary"
              >
                {loading ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <RefreshCw className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          <div className="grid gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-6">Quick Actions</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <button className="btn-export">
                  <FileDown className="w-5 h-5 mr-2" />
                  Export Report
                </button>
                <button className="btn-schedule">
                  <Clock className="w-5 h-5 mr-2" />
                  Schedule Report
                </button>
                <button className="btn-alerts">
                  <Bell className="w-5 h-5 mr-2" />
                  Set Alerts
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-2">
                  <FileSearch className="w-6 h-6 text-teal-500" />
                  <span className="text-green-500 flex items-center">
                    <ArrowUp className="w-4 h-4 mr-1" />
                    15%
                  </span>
                </div>
                <h3 className="text-2xl font-bold">2,345</h3>
                <p className="text-gray-600">Organic Visits</p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-2">
                  <ExternalLink className="w-6 h-6 text-teal-500" />
                  <span className="text-red-500 flex items-center">
                    <ArrowDown className="w-4 h-4 mr-1" />
                    3%
                  </span>
                </div>
                <h3 className="text-2xl font-bold">4.2%</h3>
                <p className="text-gray-600">Click-through Rate</p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-2">
                  <FileSearch className="w-6 h-6 text-teal-500" />
                  <span className="text-green-500 flex items-center">
                    <ArrowUp className="w-4 h-4 mr-1" />
                    8%
                  </span>
                </div>
                <h3 className="text-2xl font-bold">12</h3>
                <p className="text-gray-600">Ranking Keywords</p>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center justify-between mb-2">
                  <FileSearch className="w-6 h-6 text-teal-500" />
                  <span className="text-green-500 flex items-center">
                    <ArrowUp className="w-4 h-4 mr-1" />
                    5%
                  </span>
                </div>
                <h3 className="text-2xl font-bold">3.8</h3>
                <p className="text-gray-600">Average Position</p>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-6">Organic Traffic Trend</h2>
              <Line options={chartOptions} data={chartData} />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-6">Top Keywords</h2>
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b">
                        <th className="text-left py-2">Keyword</th>
                        <th className="text-right py-2">Position</th>
                        <th className="text-right py-2">Change</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="py-2">AI automation</td>
                        <td className="text-right">2</td>
                        <td className="text-right text-green-500">+1</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2">Business transformation</td>
                        <td className="text-right">4</td>
                        <td className="text-right text-red-500">-2</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2">Digital strategy</td>
                        <td className="text-right">3</td>
                        <td className="text-right text-green-500">+2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-bold mb-6">Page Performance</h2>
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b">
                        <th className="text-left py-2">Page</th>
                        <th className="text-right py-2">Views</th>
                        <th className="text-right py-2">CTR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="py-2">/services</td>
                        <td className="text-right">1,234</td>
                        <td className="text-right">5.2%</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2">/about</td>
                        <td className="text-right">987</td>
                        <td className="text-right">4.8%</td>
                      </tr>
                      <tr className="border-b">
                        <td className="py-2">/blog</td>
                        <td className="text-right">756</td>
                        <td className="text-right">3.9%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default SEODashboard;