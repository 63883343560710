import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Users, Target, Award, Star, Heart, Lightbulb, HandshakeIcon, Zap, Globe, ArrowRight } from 'lucide-react';
import { WavyBackground } from '../components/ui/wavy-background';

const About = () => {
  const coreValues = [
    {
      icon: <Star className="w-6 h-6 text-teal-500" />,
      title: "Excellence",
      description: "We strive for uncompromising quality in every service and solution, continuously raising the bar and exceeding our clients' expectations."
    },
    {
      icon: <Award className="w-6 h-6 text-teal-500" />,
      title: "Integrity",
      description: "Our commitment to ethical conduct underpins everything we do—promoting honesty, transparency, and accountability in all interactions."
    },
    {
      icon: <Lightbulb className="w-6 h-6 text-teal-500" />,
      title: "Innovation",
      description: "We prioritize forward-thinking and creativity, constantly seeking new approaches and adapting to emerging trends to keep clients at the forefront of their industries."
    },
    {
      icon: <Heart className="w-6 h-6 text-teal-500" />,
      title: "Empathy",
      description: "We deeply understand the unique challenges and goals of each client, offering tailored support delivered with compassion and respect."
    },
    {
      icon: <HandshakeIcon className="w-6 h-6 text-teal-500" />,
      title: "Collaboration",
      description: "We believe in the power of teamwork—bringing together diverse perspectives, fostering an inclusive environment, and building lasting partnerships with our clients and community."
    },
    {
      icon: <Zap className="w-6 h-6 text-teal-500" />,
      title: "Impact",
      description: "Our driving motivation is to make a positive difference—creating transformative change for our clients, their organizations, and the communities they serve."
    }
  ];

  return (
    <main>
      {/* Hero Section */}
      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[400px] lg:min-h-[500px]"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">About Sightline Global</h1>
            <p className="text-lg md:text-xl text-silver-300 max-w-2xl mx-auto">
              Pioneering the future of business through innovative AI solutions and strategic transformation.
            </p>
          </motion.div>
        </WavyBackground>
      </section>

      {/* Mission & Vision Section */}
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <div className="flex items-center mb-6">
                <Target className="w-8 h-8 text-teal-500 mr-4" />
                <h2 className="text-2xl font-bold">Our Mission</h2>
              </div>
              <p className="text-gray-600">
                Empower individuals and organizations with innovative, comprehensive, and personalized solutions that enhance professional growth, unlock potential, and drive meaningful business outcomes across diverse industries.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <div className="flex items-center mb-6">
                <Globe className="w-8 h-8 text-teal-500 mr-4" />
                <h2 className="text-2xl font-bold">Our Vision</h2>
              </div>
              <p className="text-gray-600">
                To be a global catalyst for transformation—where collaborative strategies, industry-leading expertise, and cutting-edge solutions converge to inspire success, foster community, and uplift the lives of those we serve.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="bg-gray-50 py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-4">Our Core Values</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              These principles guide everything we do at Sightline Global.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {coreValues.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-lg"
              >
                <div className="flex items-center mb-4">
                  {value.icon}
                  <h3 className="text-xl font-bold ml-3">{value.title}</h3>
                </div>
                <p className="text-gray-600">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Founder's Message */}
      <section className="py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto"
          >
            <div className="text-center mb-12">
              <div className="w-32 h-32 mx-auto mb-4 relative">
                <img
                  src="https://grey-cheddar-699.notion.site/image/attachment%3A44d5250a-731b-4b0f-8f39-2a081650843c%3A8U_D2RnR1nE.jpeg?table=block&id=1ad269ac-5dc0-80d7-a863-cf0ffbe7562a&spaceId=79753506-7e0d-4e21-9d50-5b59107bdd04&width=1420&userId=&cache=v2"
                  alt="Quentin Cade Sr."
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
              <h3 className="text-2xl font-bold">Quentin Cade Sr.</h3>
              <p className="text-gray-600">Founder & CEO</p>
            </div>

            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-6">A Message from Our Founder</h2>
              <div className="space-y-6 text-gray-600">
                <p>
                  At Sightline Global, we harness the strength of multiple divisions and expertise to provide integrated, high-quality services. This synergy ensures our clients receive end-to-end support and strategic guidance—every step of the way.
                </p>
                <p>
                  We continuously refine our services, integrating fresh insights and the latest technologies. Our relentless focus on high standards means our clients benefit from cutting-edge strategies that enable them to stay ahead of industry shifts.
                </p>
                <p>
                  Whether you are launching a career, scaling a business, or refining operational strategies, Sightline Global offers bespoke solutions designed to meet you where you are—and guide you to where you want to be.
                </p>
                <p>
                  By choosing Sightline Global, you gain a trusted ally committed to walking alongside you, offering expert advice, encouragement, and support as you navigate your path to success.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Community Impact Section */}
      <section className="bg-gray-50 py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto text-center"
          >
            <h2 className="text-3xl font-bold mb-6">Giving Back & Building Community</h2>
            <p className="text-gray-600 mb-8">
              We are dedicated to uplifting others, acknowledging that our success is deeply connected to collective growth. We support and mentor individuals, businesses, and wider communities—creating opportunities for them to learn, flourish, and prosper.
            </p>
            <Link to="/contact" className="btn btn-primary">
              Join Our Journey
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default About;