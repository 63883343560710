import React from 'react';
import { motion } from 'framer-motion';
import { WavyBackground } from '../components/ui/wavy-background';
import { useBrands } from '../hooks/useBrands';
import { Loader2 } from 'lucide-react';
import SEO from '../components/SEO';

const Brands = () => {
  const { brands, loading, error } = useBrands();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center space-x-2 text-teal-500">
          <Loader2 className="w-6 h-6 animate-spin" />
          <span>Loading brands...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Oops! Something went wrong</h2>
          <p className="text-gray-600">Please try again later</p>
        </div>
      </div>
    );
  }

  return (
    <main>
      <SEO
        title="Our Brands"
        description="Discover our portfolio of innovative technology solutions and brands designed to transform businesses and drive growth."
      />
      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[400px] lg:min-h-[500px]"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Brands</h1>
            <p className="text-lg md:text-xl text-silver-300 max-w-2xl mx-auto">
              Discover our portfolio of innovative technology solutions designed to transform businesses.
            </p>
          </motion.div>
        </WavyBackground>
      </section>

      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {brands.map((brand, index) => (
              <motion.div
                key={brand.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="group relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-all h-[300px]"
              >
                <div className="absolute inset-0">
                  <img
                    src={brand.image_url}
                    alt={brand.name}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-navy-700/90 via-navy-700/50 to-transparent" />
                </div>
                <div className="absolute bottom-0 left-0 right-0 p-8">
                  <h3 className="text-3xl font-bold text-white mb-2">{brand.name}</h3>
                  <p className="text-silver-300 text-lg">{brand.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Brands;