import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FileText, Settings, Users, BarChart, Search, Zap } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import SEO from '../../components/SEO';

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="Admin Dashboard"
        description="Manage your Sightline Global content, services, and analytics from the admin dashboard."
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-bold">Admin Dashboard</h1>
            <div className="text-gray-600">
              Welcome, {user?.email}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <Link
              to="/admin/posts"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <FileText className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">Blog Posts</h2>
              <p className="text-gray-600">Manage your blog content</p>
            </Link>

            <Link
              to="/admin/services"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <Settings className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">Services</h2>
              <p className="text-gray-600">Update service offerings</p>
            </Link>

            <Link
              to="/admin/brands"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <Users className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">Brands</h2>
              <p className="text-gray-600">Manage brand portfolio</p>
            </Link>

            <Link
              to="/admin/analytics"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <BarChart className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">Analytics</h2>
              <p className="text-gray-600">View site statistics</p>
            </Link>

            <Link
              to="/admin/seo"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <Search className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">SEO Dashboard</h2>
              <p className="text-gray-600">Monitor search performance</p>
            </Link>
            
            <Link
              to="/admin/pagespeed"
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <Zap className="w-8 h-8 text-teal-500 mb-4" />
              <h2 className="text-xl font-semibold mb-2">PageSpeed Insights</h2>
              <p className="text-gray-600">Analyze website performance</p>
            </Link>
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Dashboard;