import React from 'react';
import { motion } from 'framer-motion';
import { Bot, Rocket, LineChart, FileText, Loader2 } from 'lucide-react';
import { WavyBackground } from '../components/ui/wavy-background';
import { useServices } from '../hooks/useServices';
import SEO from '../components/SEO';

const iconMap = {
  'Bot': Bot,
  'Rocket': Rocket,
  'LineChart': LineChart,
  'FileText': FileText
};

const Services = () => {
  const { services, loading, error } = useServices();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex items-center space-x-2 text-teal-500">
          <Loader2 className="w-6 h-6 animate-spin" />
          <span>Loading services...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Oops! Something went wrong</h2>
          <p className="text-gray-600">Please try again later</p>
        </div>
      </div>
    );
  }

  return (
    <main>
      <SEO
        title="Our Services"
        description="Discover our comprehensive AI solutions including automation, digital transformation, and strategic growth services. Transform your business with cutting-edge technology."
      />
      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[400px] lg:min-h-[500px]"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Services</h1>
            <p className="text-lg md:text-xl text-silver-300 max-w-2xl mx-auto">
              Comprehensive solutions to drive your business forward through AI innovation and strategic transformation.
            </p>
          </motion.div>
        </WavyBackground>
      </section>

      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {services.map((service, index) => {
              const IconComponent = iconMap[service.icon as keyof typeof iconMap] || Bot;
              
              return (
                <motion.div
                  key={service.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-all"
                >
                  <div className="mb-6">
                    <IconComponent className="w-12 h-12 text-teal-500" />
                  </div>
                  <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <ul className="space-y-3">
                    {service.features.map((feature, fIndex) => (
                      <li key={fIndex} className="flex items-center text-gray-700">
                        <div className="w-2 h-2 bg-teal-500 rounded-full mr-3" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Services;