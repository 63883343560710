import { supabase } from '../lib/supabase';

export interface ImageMetadata {
  id: string;
  post_id: string;
  url: string;
  filename: string;
  size: number;
  width?: number;
  height?: number;
  alt_text?: string;
  caption?: string;
  created_at: string;
  updated_at: string;
}

export interface ImageUploadOptions {
  postId?: string;
  altText?: string;
  caption?: string;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const validateImage = (file: File): string | null => {
  if (!ALLOWED_TYPES.includes(file.type)) {
    return 'Invalid file type. Please upload a JPG, PNG, GIF, or WebP image.';
  }

  if (file.size > MAX_FILE_SIZE) {
    return 'File size exceeds 5MB limit.';
  }

  return null;
};

export const uploadImage = async (
  file: File,
  options: ImageUploadOptions = {}
): Promise<ImageMetadata> => {
  const error = validateImage(file);
  if (error) {
    throw new Error(error);
  }

  // Generate a unique filename
  const timestamp = Date.now();
  const extension = file.name.split('.').pop();
  const filename = `${timestamp}-${Math.random().toString(36).substring(2)}.${extension}`;
  const path = `blog-images/${filename}`;

  try {
    // Upload to Supabase Storage
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from('public')
      .upload(path, file);

    if (uploadError) {
      throw uploadError;
    }

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
      .from('public')
      .getPublicUrl(path);

    // Get image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // Save metadata to database
    const { data: imageData, error: dbError } = await supabase
      .from('blog_post_images')
      .insert({
        post_id: options.postId,
        url: publicUrl,
        filename: file.name,
        size: file.size,
        width: img.naturalWidth,
        height: img.naturalHeight,
        alt_text: options.altText,
        caption: options.caption
      })
      .select()
      .single();

    if (dbError) {
      throw dbError;
    }

    return imageData;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const getPostImages = async (postId: string): Promise<ImageMetadata[]> => {
  const { data, error } = await supabase
    .from('blog_post_images')
    .select('*')
    .eq('post_id', postId)
    .order('created_at', { ascending: true });

  if (error) {
    throw error;
  }

  return data || [];
};

export const deleteImage = async (id: string): Promise<void> => {
  const { error } = await supabase
    .from('blog_post_images')
    .delete()
    .eq('id', id);

  if (error) {
    throw error;
  }
};