import { useState, useEffect } from 'react';
import { getBlogPostBySlug, BlogPost } from '../services/blog';
import { useAuth } from '../contexts/AuthContext';
import { isAdmin } from '../lib/auth';

export const useBlogPost = (slug: string) => {
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();
  const userIsAdmin = user && isAdmin(user);

  useEffect(() => {
    const fetchPost = async () => {
      if (!slug) {
        setError(new Error('No slug provided'));
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        const data = await getBlogPostBySlug(slug);
        
        if (!data) {
          throw new Error('Post not found');
        }
        
        // For non-admin users, check if the post should be visible
        if (!userIsAdmin) {
          const now = new Date();
          const isPublished = data.published;
          const isScheduledForFuture = data.scheduled_publish_date && 
            new Date(data.scheduled_publish_date) > now;
          
          if (!isPublished || isScheduledForFuture) {
            throw new Error('Post not found');
          }
        }
        
        setPost(data);
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch blog post'));
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, userIsAdmin]);

  return { post, loading, error };
};