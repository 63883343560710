import { useState, useEffect } from 'react';
import { getBrands, Brand } from '../services/brands';

export const useBrands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getBrands();
        setBrands(data);
      } catch (err) {
        console.error('Error fetching brands:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch brands'));
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, []);

  return { brands, loading, error };
};