import { supabase } from '../lib/supabase';

export interface BlogPost {
  id: string;
  title: string;
  slug: string;
  content: string;
  excerpt: string;
  image_url: string;
  author: string;
  author_title: string;
  category: string;
  type: string;
  read_time: string;
  published: boolean;
  created_at: string;
  updated_at: string;
  scheduled_publish_date?: string | null;
  stats?: {
    [key: string]: {
      label: string;
      value: string;
    };
  };
  key_highlights?: string[];
  youtube_url?: string;
  html_content?: string;
}

export const getBlogPosts = async (): Promise<BlogPost[]> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching blog posts:', error);
      throw error;
    }

    return data || [];
  } catch (error) {
    console.error('Error in getBlogPosts:', error);
    throw error;
  }
};

export const getPublishedBlogPosts = async (): Promise<BlogPost[]> => {
  try {
    const now = new Date().toISOString();
    
    // Using two separate queries and combining results instead of using the OR operation
    const { data: publishedWithoutSchedule, error: error1 } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('published', true)
      .is('scheduled_publish_date', null)
      .order('created_at', { ascending: false });

    const { data: publishedWithPastSchedule, error: error2 } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('published', true)
      .lt('scheduled_publish_date', now)
      .order('created_at', { ascending: false });

    if (error1 || error2) {
      console.error('Error fetching published blog posts:', error1 || error2);
      throw error1 || error2;
    }

    // Combine both result sets
    const combinedResults = [
      ...(publishedWithoutSchedule || []),
      ...(publishedWithPastSchedule || [])
    ];
    
    // Sort by creation date
    combinedResults.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    
    return combinedResults;
  } catch (error) {
    console.error('Error in getPublishedBlogPosts:', error);
    throw error;
  }
};

export const getBlogPostBySlug = async (slug: string): Promise<BlogPost | null> => {
  try {
    // Fix: Retrieve the blog post without filtering by publication status
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('slug', slug)
      .single();

    if (error) {
      console.error('Error fetching blog post by slug:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Error in getBlogPostBySlug:', error);
    return null;
  }
};

export const getBlogPostById = async (id: string): Promise<BlogPost | null> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error fetching blog post:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in getBlogPostById:', error);
    throw error;
  }
};

export const updateBlogPost = async (id: string, post: Partial<BlogPost>): Promise<BlogPost> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .update({
        ...post,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) {
      console.error('Error updating blog post:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in updateBlogPost:', error);
    throw error;
  }
};

export const createBlogPost = async (post: Partial<BlogPost>): Promise<BlogPost> => {
  try {
    const { data, error } = await supabase
      .from('blog_posts')
      .insert({
        ...post,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .select()
      .single();

    if (error) {
      console.error('Error creating blog post:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in createBlogPost:', error);
    throw error;
  }
};

export const checkSlugAvailability = async (slug: string, currentPostId?: string): Promise<boolean> => {
  try {
    const query = supabase
      .from('blog_posts')
      .select('id')
      .eq('slug', slug);
    
    if (currentPostId) {
      query.neq('id', currentPostId);
    }
    
    const { data, error } = await query;

    if (error) {
      throw error;
    }

    return data.length === 0; // Slug is available if no posts found with that slug
  } catch (error) {
    console.error('Error checking slug availability:', error);
    throw error;
  }
};