import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft, Shield, ExternalLink } from 'lucide-react';
import SEO from '../components/SEO';

const PrivacyPolicy = () => {
  return (
    <main className="min-h-screen bg-gray-50">
      <SEO
        title="Privacy Policy"
        description="Learn about how Sightline Global protects your privacy and handles your personal information."
      />
      <section className="bg-navy-700 text-white py-16">
        <div className="container">
          <Link
            to="/"
            className="inline-flex items-center text-silver-300 hover:text-white mb-8 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </Link>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center gap-4 mb-6">
              <Shield className="w-8 h-8 text-teal-500" />
              <h1 className="text-4xl font-bold">Privacy Policy</h1>
            </div>
            <p className="text-silver-300">
              Last updated: March 20, 2024
            </p>
          </motion.div>
        </div>
      </section>

      <section className="py-16">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8"
          >
            <div className="prose max-w-none">
              <h2 className="text-2xl font-bold mb-6">Introduction</h2>
              <p className="mb-6">
                At Sightline Global, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
              </p>

              <h2 className="text-2xl font-bold mb-6">Information We Collect</h2>
              <h3 className="text-xl font-semibold mb-4">Personal Information</h3>
              <p className="mb-4">We may collect personal information that you voluntarily provide to us when you:</p>
              <ul className="list-disc list-inside mb-6">
                <li>Fill out forms on our website</li>
                <li>Create an account</li>
                <li>Subscribe to our newsletter</li>
                <li>Request information about our services</li>
                <li>Contact us for support</li>
              </ul>

              <h3 className="text-xl font-semibold mb-4">Automatically Collected Information</h3>
              <p className="mb-6">
                When you visit our website, we automatically collect certain information about your device, including:
              </p>
              <ul className="list-disc list-inside mb-6">
                <li>IP address</li>
                <li>Browser type</li>
                <li>Operating system</li>
                <li>Access times</li>
                <li>Pages viewed</li>
              </ul>

              <h2 className="text-2xl font-bold mb-6">How We Use Your Information</h2>
              <p className="mb-4">We use the information we collect to:</p>
              <ul className="list-disc list-inside mb-6">
                <li>Provide and maintain our services</li>
                <li>Improve our website and user experience</li>
                <li>Communicate with you about our services</li>
                <li>Send newsletters and marketing communications</li>
                <li>Respond to your inquiries and support requests</li>
                <li>Detect and prevent fraud</li>
              </ul>

              <h2 className="text-2xl font-bold mb-6">Information Sharing</h2>
              <p className="mb-6">
                We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy or as required by law.
              </p>

              <h2 className="text-2xl font-bold mb-6">Security</h2>
              <p className="mb-6">
                We implement appropriate technical and organizational measures to maintain the security of your personal information, but remember that no method of transmission over the Internet is 100% secure.
              </p>

              <h2 className="text-2xl font-bold mb-6">Your Rights</h2>
              <p className="mb-4">You have the right to:</p>
              <ul className="list-disc list-inside mb-6">
                <li>Access your personal information</li>
                <li>Correct inaccurate information</li>
                <li>Request deletion of your information</li>
                <li>Object to our use of your information</li>
                <li>Withdraw consent for marketing communications</li>
              </ul>

              <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
              <p className="mb-6">
                If you have questions about this Privacy Policy, please contact us at:
                <br className="mb-2" />
                <a 
                  href="mailto:privacy@sightlineglobal.com" 
                  className="inline-flex items-center text-teal-500 hover:text-teal-600"
                >
                  privacy@sightlineglobal.com
                  <ExternalLink className="w-4 h-4 ml-1" />
                </a>
              </p>
            </div>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;