import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Save, Loader2, AlertCircle, CheckCircle, Bot, Calendar } from 'lucide-react';
import { BlogPost, getBlogPostById, updateBlogPost, createBlogPost, checkSlugAvailability } from '../../services/blog';
import RichTextEditor from '../../components/RichTextEditor';
import ImageUploader from '../../components/ImageUploader';
import SEO from '../../components/SEO';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import AIPostGenerator from '../../components/AIPostGenerator';

const PostEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showAIGenerator, setShowAIGenerator] = useState(false);
  const [post, setPost] = useState<Partial<BlogPost>>({
    title: '',
    slug: '',
    content: '',
    excerpt: '',
    image_url: '',
    author: '',
    author_title: '',
    category: '',
    type: 'Insight',
    read_time: '',
    published: false,
    scheduled_publish_date: null,
    key_highlights: [],
    youtube_url: '',
    stats: {}
  });

  useEffect(() => {
    const fetchPost = async () => {
      if (!id) return;
      
      setLoading(true);
      try {
        const data = await getBlogPostById(id);
        if (data) {
          setPost(data);
        } else {
          setError('Post not found');
          navigate('/admin/posts');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setPost(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null);
    setSuccess(null);
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const slug = value.toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    
    setPost(prev => ({
      ...prev,
      slug
    }));
    setError(null);
    setSuccess(null);
  };

  const handleContentChange = (content: string) => {
    setPost(prev => ({
      ...prev,
      content
    }));
    setError(null);
    setSuccess(null);
  };

  const handleScheduledDateChange = (date: Date | null) => {
    setPost(prev => ({
      ...prev,
      scheduled_publish_date: date ? date.toISOString() : null
    }));
    setError(null);
    setSuccess(null);
  };

  const handleImageUpload = (imageUrl: string) => {
    setPost(prev => ({
      ...prev,
      image_url: imageUrl
    }));
  };

  const handleKeyHighlightsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const highlights = e.target.value.split('\n').filter(h => h.trim());
    setPost(prev => ({
      ...prev,
      key_highlights: highlights
    }));
  };

  const handleStatsChange = (key: string, field: 'label' | 'value', value: string) => {
    setPost(prev => ({
      ...prev,
      stats: {
        ...prev.stats,
        [key]: {
          ...prev.stats?.[key],
          [field]: value
        }
      }
    }));
  };

  const handleAIGenerated = (generatedContent: {
    title: string;
    slug: string;
    content: string;
    excerpt: string;
    key_highlights?: string[];
    stats?: {
      [key: string]: {
        label: string;
        value: string;
      };
    };
  }) => {
    setPost(prev => ({
      ...prev,
      title: generatedContent.title,
      slug: generatedContent.slug,
      content: generatedContent.content,
      excerpt: generatedContent.excerpt,
      key_highlights: generatedContent.key_highlights || prev.key_highlights,
      stats: generatedContent.stats || prev.stats
    }));
    
    setShowAIGenerator(false);
    setSuccess('AI content generated successfully! Review and edit as needed before saving.');
  };

  const validateForm = async () => {
    if (!post.title) return 'Title is required';
    if (!post.slug) return 'Slug is required';
    if (!post.content) return 'Content is required';
    if (!post.excerpt) return 'Excerpt is required';
    if (!post.image_url) return 'Featured image is required';
    if (!post.category) return 'Category is required';
    if (!post.type) return 'Type is required';
    if (!post.read_time) return 'Read time is required';
    
    // Check if slug is unique
    if (!id) {
      const isSlugAvailable = await checkSlugAvailability(post.slug);
      if (!isSlugAvailable) {
        return 'Slug is already in use. Please choose a different one.';
      }
    } else {
      const isSlugAvailable = await checkSlugAvailability(post.slug, id);
      if (!isSlugAvailable) {
        return 'Slug is already in use. Please choose a different one.';
      }
    }
    
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    setSaving(true);
    setError(null);
    setSuccess(null);
    
    try {
      const validationError = await validateForm();
      if (validationError) {
        setError(validationError);
        setSaving(false);
        return;
      }

      if (id) {
        await updateBlogPost(id, post);
        setSuccess('Post updated successfully');
      } else {
        await createBlogPost(post);
        setSuccess('Post created successfully');
        navigate('/admin/posts');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save post');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-[calc(100vh-5rem)] flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-teal-500 mx-auto mb-4" />
          <p className="text-gray-600">Loading post...</p>
        </div>
      </div>
    );
  }

  const scheduledDate = post.scheduled_publish_date 
    ? new Date(post.scheduled_publish_date) 
    : null;

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title={id ? 'Edit Post' : 'New Post'}
        description="Create or edit a blog post"
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/admin/posts')}
                className="mr-4 text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="w-6 h-6" />
              </button>
              <h1 className="text-3xl font-bold">{id ? 'Edit Post' : 'New Post'}</h1>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowAIGenerator(true)}
                className="btn bg-purple-600 hover:bg-purple-700 text-white"
              >
                <Bot className="w-5 h-5 mr-2" />
                Generate with AI
              </button>
              <button
                onClick={handleSubmit}
                disabled={saving}
                className="btn btn-primary"
              >
                {saving ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5 mr-2" />
                    Save Post
                  </>
                )}
              </button>
            </div>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center text-red-700">
              <AlertCircle className="w-5 h-5 mr-3 flex-shrink-0" />
              {error}
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 rounded-lg flex items-center text-green-700">
              <CheckCircle className="w-5 h-5 mr-3 flex-shrink-0" />
              {success}
            </div>
          )}

          <div className="bg-white rounded-lg shadow-lg p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={post.title}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Slug
                  </label>
                  <input
                    type="text"
                    name="slug"
                    value={post.slug}
                    onChange={handleSlugChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Featured Image
                </label>
                <ImageUploader
                  postId={id}
                  onUpload={handleImageUpload}
                  onError={setError}
                />
                {post.image_url && (
                  <img
                    src={post.image_url}
                    alt="Featured"
                    className="mt-4 h-48 w-full object-cover rounded-lg"
                  />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Excerpt
                </label>
                <textarea
                  name="excerpt"
                  value={post.excerpt}
                  onChange={handleChange}
                  required
                  rows={2}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Content
                </label>
                <RichTextEditor
                  content={post.content || ''}
                  onChange={handleContentChange}
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Type
                  </label>
                  <select
                    name="type"
                    value={post.type}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  >
                    <option value="Insight">Insight</option>
                    <option value="Case Study">Case Study</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    value={post.category}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Read Time
                  </label>
                  <input
                    type="text"
                    name="read_time"
                    value={post.read_time}
                    onChange={handleChange}
                    required
                    placeholder="e.g., 5 min read"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Author
                  </label>
                  <input
                    type="text"
                    name="author"
                    value={post.author}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Author Title
                  </label>
                  <input
                    type="text"
                    name="author_title"
                    value={post.author_title}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Key Highlights (one per line)
                </label>
                <textarea
                  value={post.key_highlights?.join('\n')}
                  onChange={handleKeyHighlightsChange}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  YouTube URL (optional)
                </label>
                <input
                  type="url"
                  name="youtube_url"
                  value={post.youtube_url}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                />
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Statistics</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {['stat1', 'stat2', 'stat3'].map((key) => (
                    <div key={key} className="space-y-2">
                      <input
                        type="text"
                        value={post.stats?.[key]?.label || ''}
                        onChange={(e) => handleStatsChange(key, 'label', e.target.value)}
                        placeholder="Label"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                      <input
                        type="text"
                        value={post.stats?.[key]?.value || ''}
                        onChange={(e) => handleStatsChange(key, 'value', e.target.value)}
                        placeholder="Value"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="border-t border-gray-200 pt-6 space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Publication Settings
                  </label>
                  
                  <div className="flex flex-col space-y-4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="published"
                        checked={post.published}
                        onChange={(e) => setPost(prev => ({ ...prev, published: e.target.checked }))}
                        className="h-4 w-4 text-teal-500 focus:ring-teal-500 border-gray-300 rounded"
                      />
                      <label htmlFor="published" className="ml-2 block text-sm text-gray-900">
                        Published
                      </label>
                    </div>
                    
                    <div className="mt-4">
                      <div className="flex items-center mb-2">
                        <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                        <label className="block text-sm font-medium text-gray-700">
                          Schedule Publication (optional)
                        </label>
                      </div>
                      <div className="relative">
                        <DatePicker
                          selected={scheduledDate}
                          onChange={handleScheduledDateChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select future date and time to publish"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                          minDate={new Date()}
                          isClearable
                        />
                      </div>
                      {scheduledDate && (
                        <p className="mt-2 text-sm text-teal-600">
                          This post will be automatically published on {format(scheduledDate, 'MMMM d, yyyy')} at {format(scheduledDate, 'h:mm aa')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      
      {showAIGenerator && (
        <AIPostGenerator
          onGenerated={handleAIGenerated}
          onClose={() => setShowAIGenerator(false)}
        />
      )}
    </main>
  );
};

export default PostEditor;