import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Bot, Rocket, LineChart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { WavyBackground } from '../components/ui/wavy-background';
import SEO from '../components/SEO';

const Home = () => {
  return (
    <main>
      <SEO
        title="AI Innovation & Business Transformation"
        description="Empower your enterprise with cutting-edge AI automation and strategic growth solutions. Transform your business for the digital age with Sightline Global."
      />
      
      {/* Hero Section */}
      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[500px] lg:min-h-[600px]"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
              Transforming Business Through
              <span className="text-teal-400"> AI Innovation</span>
            </h1>
            <p className="text-lg md:text-xl text-silver-300 mb-6 max-w-2xl mx-auto">
              Empowering enterprises with cutting-edge AI automation and strategic growth solutions
              for the digital age.
            </p>
            <div className="flex flex-wrap gap-4 justify-center">
              <Link to="/contact" className="btn btn-primary">
                Get Started
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
              <Link to="/services" className="btn bg-white text-navy-700 hover:bg-silver-300">
                Our Services
              </Link>
            </div>
          </motion.div>
        </WavyBackground>
      </section>

      {/* Features Section */}
      <section className="py-20">
        <div className="container">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Why Choose Sightline Global?</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We combine AI expertise with strategic insight to deliver transformative solutions
              that drive business growth.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: <Bot className="w-12 h-12 text-teal-500" />,
                title: 'AI-Driven Automation',
                description: 'Streamline operations and boost efficiency with intelligent automation solutions.',
              },
              {
                icon: <Rocket className="w-12 h-12 text-teal-500" />,
                title: 'Digital Transformation',
                description: 'Navigate the digital landscape with innovative technology solutions.',
              },
              {
                icon: <LineChart className="w-12 h-12 text-teal-500" />,
                title: 'Strategic Growth',
                description: 'Accelerate business growth with data-driven strategies and insights.',
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-teal-500 text-white py-20">
        <div className="container text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Join the leading companies that trust Sightline Global for their digital transformation journey.
          </p>
          <Link to="/contact" className="btn bg-white text-teal-500 hover:bg-navy-700 hover:text-white">
            Schedule a Consultation
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Home;