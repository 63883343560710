import React, { useEffect } from 'react';
import { useParams, Link, Navigate, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Calendar, Clock, ArrowRight, Bot } from 'lucide-react';
import { WavyBackground } from '../components/ui/wavy-background';
import { useBlogPost } from '../hooks/useBlogPost';
import YouTubeEmbed from '../components/YouTubeEmbed';
import SEO from '../components/SEO';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { post, loading, error } = useBlogPost(slug || '');

  useEffect(() => {
    // Log for debugging
    console.log('BlogPost component mounted with slug:', slug);
    console.log('Post data:', post);
    console.log('Loading state:', loading);
    console.log('Error state:', error);
  }, [slug, post, loading, error]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-500"></div>
          <p className="text-gray-600">Loading post...</p>
        </div>
      </div>
    );
  }

  if (!post || error) {
    console.log('Navigating to /blog due to missing post or error');
    return <Navigate to="/blog" replace />;
  }

  // Use html_content if available, otherwise fall back to content
  const displayContent = post.html_content || post.content;

  return (
    <main>
      <SEO
        title={post.title}
        description={post.excerpt}
        image={post.image_url}
        article={true}
      />

      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[400px] lg:min-h-[500px]"
        >
          <Link
            to="/blog"
            className="inline-flex items-center text-silver-300 hover:text-white mb-8 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to News & Insights
          </Link>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-6">
              <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                post.type === 'Case Study' ? 'bg-teal-500' : 'bg-purple-500'
              } text-white`}>
                <Bot className="w-4 h-4 mr-2" />
                {post.type}
              </span>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-navy-800 text-white">
                {post.category}
              </span>
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">{post.title}</h1>
            <div className="flex items-center space-x-4 text-silver-300">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                {new Date(post.created_at).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </div>
              <div className="flex items-center">
                <Clock className="w-5 h-5 mr-2" />
                {post.read_time}
              </div>
            </div>
          </motion.div>
        </WavyBackground>
      </section>

      <section className="py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto"
          >
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={post.image_url}
                alt={post.title}
                className="w-full h-96 object-cover"
              />
              
              <div className="p-8">
                {post.stats && (
                  <div className="grid grid-cols-3 gap-4 mb-8 py-6 border-y border-gray-100">
                    {Object.entries(post.stats).map(([key, stat]) => (
                      <div key={key} className="text-center">
                        <div className="text-2xl font-bold text-teal-500">{stat.value}</div>
                        <div className="text-sm text-gray-600">{stat.label}</div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="prose prose-lg max-w-none">
                  <p className="text-xl text-gray-600 mb-8">{post.excerpt}</p>

                  {post.key_highlights && (
                    <div className="bg-gray-50 rounded-lg p-6 mb-8">
                      <h3 className="text-xl font-bold mb-4">Key Highlights</h3>
                      <ul className="space-y-3">
                        {post.key_highlights.map((highlight, index) => (
                          <li key={index} className="flex items-center text-gray-700">
                            <ArrowRight className="w-5 h-5 text-teal-500 mr-3 flex-shrink-0" />
                            {highlight}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {post.youtube_url && (
                    <YouTubeEmbed url={post.youtube_url} />
                  )}

                  {/* Render HTML content if available */}
                  {post.html_content ? (
                    <div 
                      className="blog-content"
                      dangerouslySetInnerHTML={{ __html: post.html_content }} 
                    />
                  ) : (
                    displayContent.split('\n\n').map((paragraph, index) => {
                      // Handle headers
                      if (paragraph.startsWith('## ')) {
                        return (
                          <h2 key={index} className="text-3xl font-bold mt-12 mb-6">
                            {paragraph.replace('## ', '')}
                          </h2>
                        );
                      }
                      
                      if (paragraph.startsWith('### ')) {
                        return (
                          <h3 key={index} className="text-2xl font-bold mt-8 mb-4">
                            {paragraph.replace('### ', '')}
                          </h3>
                        );
                      }

                      // Handle lists
                      if (paragraph.startsWith('1.')) {
                        const items = paragraph.split('\n').filter(item => item.trim());
                        return (
                          <ol key={index} className="list-decimal list-inside space-y-2 my-6">
                            {items.map((item, i) => (
                              <li key={i} className="text-gray-700">
                                {item.replace(/^\d+\.\s*/, '')}
                              </li>
                            ))}
                          </ol>
                        );
                      }

                      if (paragraph.startsWith('-')) {
                        const items = paragraph.split('\n').filter(item => item.trim());
                        return (
                          <ul key={index} className="list-disc list-inside space-y-2 my-6">
                            {items.map((item, i) => (
                              <li key={i} className="text-gray-700">
                                {item.replace(/^-\s*/, '')}
                              </li>
                            ))}
                          </ul>
                        );
                      }

                      // Regular paragraphs
                      return (
                        <p key={index} className="text-gray-700 mb-6">
                          {paragraph}
                        </p>
                      );
                    })
                  )}
                </div>

                <div className="mt-12 pt-8 border-t border-gray-100">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="font-semibold text-gray-900">{post.author}</h4>
                      <p className="text-gray-600">{post.author_title}</p>
                    </div>
                    <div className="flex space-x-4">
                      <Link to="/contact" className="btn btn-primary">
                        Get Started
                        <ArrowRight className="ml-2 w-5 h-5" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="bg-gray-50 py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business?</h2>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Let's discuss how we can help you achieve similar results for your organization.
            </p>
            <Link to="/contact" className="btn btn-primary">
              Start Your Journey
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default BlogPost;