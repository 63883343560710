import React from 'react';
import { Link } from 'react-router-dom';
import { Linkedin, Facebook } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-navy-700 text-white mt-auto">
      <div className="container py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <Link to="/" className="flex items-center space-x-2">
              <img 
                src="https://ijqrexobtumuxnqihpdl.supabase.co/storage/v1/object/sign/website-images/Global%202025.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWJzaXRlLWltYWdlcy9HbG9iYWwgMjAyNS5wbmciLCJpYXQiOjE3NDE3MzE3MjMsImV4cCI6MjAyNTU1NTcyM30.LCrwXM9nsgfCtyn6KI5z3zdJQRWQoZx1N_6RV7cSaF0" 
                alt="Sightline Global" 
                className="w-10 h-10" 
              />
              <span className="text-xl font-poppins font-bold">
                Sightline Global
              </span>
            </Link>
            <p className="mt-4 text-silver-300">
              Driving business success with AI automation and strategic growth.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="text-silver-300 hover:text-teal-500">Home</Link></li>
              <li><Link to="/about" className="text-silver-300 hover:text-teal-500">About Us</Link></li>
              <li><Link to="/services" className="text-silver-300 hover:text-teal-500">Services</Link></li>
              <li><Link to="/contact" className="text-silver-300 hover:text-teal-500">Contact</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Services</h3>
            <ul className="space-y-2">
              <li><Link to="/services" className="text-silver-300 hover:text-teal-500">AI Automation</Link></li>
              <li><Link to="/services" className="text-silver-300 hover:text-teal-500">Digital Transformation</Link></li>
              <li><Link to="/services" className="text-silver-300 hover:text-teal-500">Strategic Growth</Link></li>
              <li><Link to="/services" className="text-silver-300 hover:text-teal-500">Content Publishing</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Connect With Us</h3>
            <div className="flex space-x-4 mb-6">
              <a 
                href="https://www.linkedin.com/company/92609661/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-silver-300 hover:text-teal-500 transition-colors"
              >
                <Linkedin className="w-6 h-6" />
              </a>
              <a 
                href="https://www.facebook.com/sightline.global.llc"
                target="_blank"
                rel="noopener noreferrer"
                className="text-silver-300 hover:text-teal-500 transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
            </div>
            <div className="space-y-2">
              <Link to="/privacy" className="block text-silver-300 hover:text-teal-500">Privacy Policy</Link>
              <Link to="/terms" className="block text-silver-300 hover:text-teal-500">Terms & Conditions</Link>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-8 text-center text-silver-300">
          <p>&copy; {new Date().getFullYear()} Sightline Global. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;