import { supabase } from '../lib/supabase';

export interface Brand {
  id: string;
  name: string;
  description: string;
  image_url: string;
  created_at: string;
  updated_at: string;
}

const defaultBrands: Brand[] = [
  {
    id: '1',
    name: 'TechFlow',
    description: 'AI-powered workflow automation platform',
    image_url: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=800&q=80',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '2',
    name: 'DataSense',
    description: 'Intelligent data analytics solution',
    image_url: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&w=800&q=80',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '3',
    name: 'CloudMatrix',
    description: 'Enterprise cloud management system',
    image_url: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&w=800&q=80',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '4',
    name: 'ContentHub',
    description: 'Automated content distribution platform',
    image_url: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=800&q=80',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  }
];

export const getBrands = async (): Promise<Brand[]> => {
  try {
    const { data, error } = await supabase
      .from('brands')
      .select('*')
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching brands:', error);
      // Return default brands if database fetch fails
      return defaultBrands;
    }

    return data?.length ? data : defaultBrands;
  } catch (error) {
    console.error('Error in getBrands:', error);
    // Return default brands if there's an error
    return defaultBrands;
  }
};