import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Filter } from 'lucide-react';
import { Link } from 'react-router-dom';
import { WavyBackground } from '../components/ui/wavy-background';
import { useBlogPosts } from '../hooks/useBlogPosts';
import BlogCard from '../components/BlogCard';
import SEO from '../components/SEO';

const Blog = () => {
  const [filter, setFilter] = useState('all');
  const { posts, loading, error } = useBlogPosts();

  const filteredPosts = filter === 'all' 
    ? posts 
    : posts.filter(post => post.type.toLowerCase() === filter.toLowerCase());

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-500"></div>
          <p className="text-gray-600">Loading posts...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Error Loading Posts</h2>
          <p className="text-gray-600 mb-4">{error.message}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="text-teal-500 hover:text-teal-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <main>
      <SEO
        title="News & Insights"
        description="Explore our collection of case studies, industry insights, and success stories showcasing the transformative power of AI in business."
      />

      <section className="relative bg-navy-700 text-white">
        <WavyBackground 
          className="max-w-4xl mx-auto px-4 py-16"
          colors={['#14B8A6', '#0D9488', '#0F766E', '#115E59', '#134E4A']}
          backgroundFill="#1E2B3C"
          blur={5}
          speed="slow"
          waveOpacity={0.5}
          waveWidth={100}
          containerClassName="min-h-[400px] lg:min-h-[500px]"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Insights & Success Stories</h1>
            <p className="text-lg md:text-xl text-silver-300 max-w-2xl mx-auto">
              Explore our collection of case studies and industry insights that showcase the transformative power of AI and innovation in business.
            </p>
          </motion.div>
        </WavyBackground>
      </section>

      <section className="py-20">
        <div className="container">
          <div className="flex items-center justify-between mb-12">
            <h2 className="text-2xl font-bold">Latest Content ({filteredPosts.length} posts)</h2>
            <div className="flex items-center space-x-4">
              <Filter className="w-5 h-5 text-gray-500" />
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              >
                <option value="all">All Content</option>
                <option value="case study">Case Studies</option>
                <option value="insight">Insights</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {filteredPosts.map((post) => (
              <BlogCard key={post.id} post={post} />
            ))}
          </div>

          {filteredPosts.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-600 mb-4">No posts found for the selected filter.</p>
              <button
                onClick={() => setFilter('all')}
                className="text-teal-500 hover:text-teal-600"
              >
                View All Posts
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="bg-gray-50 py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h2 className="text-3xl font-bold mb-6">Ready to Write Your Success Story?</h2>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Let's discuss how we can help transform your business and create remarkable results together.
            </p>
            <Link to="/contact" className="btn btn-primary">
              Start the Conversation
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default Blog;