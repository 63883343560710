import React from 'react';
import { motion } from 'framer-motion';
import { BarChart2, TrendingUp, Users, Clock } from 'lucide-react';
import SEO from '../../components/SEO';

const Analytics = () => {
  // Sample data - replace with real analytics data
  const stats = [
    {
      label: 'Total Visitors',
      value: '12,345',
      change: '+15%',
      icon: Users,
      positive: true
    },
    {
      label: 'Avg. Session Duration',
      value: '3m 45s',
      change: '+8%',
      icon: Clock,
      positive: true
    },
    {
      label: 'Conversion Rate',
      value: '3.2%',
      change: '-0.5%',
      icon: TrendingUp,
      positive: false
    },
    {
      label: 'Bounce Rate',
      value: '42%',
      change: '-3%',
      icon: BarChart2,
      positive: true
    }
  ];

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="Analytics Dashboard"
        description="View and analyze website performance metrics"
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-3xl font-bold mb-8">Analytics Dashboard</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <motion.div
                  key={stat.label}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="bg-white p-6 rounded-lg shadow-lg"
                >
                  <div className="flex items-center justify-between mb-4">
                    <Icon className="w-8 h-8 text-teal-500" />
                    <span className={`text-sm font-medium ${
                      stat.positive ? 'text-green-600' : 'text-red-600'
                    }`}>
                      {stat.change}
                    </span>
                  </div>
                  <h3 className="text-2xl font-bold mb-1">{stat.value}</h3>
                  <p className="text-gray-600">{stat.label}</p>
                </motion.div>
              );
            })}
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Traffic Sources</h2>
              <div className="h-64 flex items-center justify-center text-gray-500">
                Chart placeholder - Integration needed
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">User Engagement</h2>
              <div className="h-64 flex items-center justify-center text-gray-500">
                Chart placeholder - Integration needed
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Analytics;