import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowLeft, ScrollText } from 'lucide-react';
import SEO from '../components/SEO';

const Terms = () => {
  return (
    <main className="min-h-screen bg-gray-50">
      <SEO
        title="Terms and Conditions"
        description="Read our terms and conditions to understand your rights and responsibilities when using Sightline Global's services."
      />

      <section className="bg-navy-700 text-white py-16">
        <div className="container">
          <Link
            to="/"
            className="inline-flex items-center text-silver-300 hover:text-white mb-8 transition-colors"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Home
          </Link>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center gap-4 mb-6">
              <ScrollText className="w-8 h-8 text-teal-500" />
              <h1 className="text-4xl font-bold">Terms and Conditions</h1>
            </div>
            <p className="text-silver-300">
              Last updated: March 20, 2024
            </p>
          </motion.div>
        </div>
      </section>

      <section className="py-16">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8"
          >
            <div className="prose max-w-none">
              <h2 className="text-2xl font-bold mb-6">Agreement to Terms</h2>
              <p className="mb-6">
                These Terms and Conditions constitute a legally binding agreement made between you and Sightline Global, concerning your access to and use of our website and services.
              </p>

              <h2 className="text-2xl font-bold mb-6">Use License</h2>
              <p className="mb-6">
                Permission is granted to temporarily access and use our website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
              </p>
              <ul className="list-disc list-inside mb-6">
                <li>Modify or copy the materials</li>
                <li>Use the materials for any commercial purpose</li>
                <li>Attempt to decompile or reverse engineer any software</li>
                <li>Remove any copyright or other proprietary notations</li>
                <li>Transfer the materials to another person</li>
              </ul>

              <h2 className="text-2xl font-bold mb-6">Disclaimer</h2>
              <p className="mb-6">
                The materials on our website are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
              </p>

              <h2 className="text-2xl font-bold mb-6">Limitations</h2>
              <p className="mb-6">
                In no event shall Sightline Global or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use our materials, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage.
              </p>

              <h2 className="text-2xl font-bold mb-6">Accuracy of Materials</h2>
              <p className="mb-6">
                The materials appearing on our website could include technical, typographical, or photographic errors. We do not warrant that any of the materials on our website are accurate, complete, or current.
              </p>

              <h2 className="text-2xl font-bold mb-6">Links</h2>
              <p className="mb-6">
                We have not reviewed all of the sites linked to our website and are not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by us of the site. Use of any such linked website is at the user's own risk.
              </p>

              <h2 className="text-2xl font-bold mb-6">Modifications</h2>
              <p className="mb-6">
                We may revise these terms of service at any time without notice. By using this website, you are agreeing to be bound by the then current version of these terms of service.
              </p>

              <h2 className="text-2xl font-bold mb-6">Governing Law</h2>
              <p className="mb-6">
                These terms and conditions are governed by and construed in accordance with the laws of the United States and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
              </p>

              <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
              <p className="mb-6">
                If you have any questions about these Terms and Conditions, please contact us at:
                <br />
                <a href="mailto:legal@sightlineglobal.com" className="text-teal-500 hover:text-teal-600">
                  legal@sightlineglobal.com
                </a>
              </p>
            </div>
          </motion.div>
        </div>
      </section>
    </main>
  );
};

export default Terms;