import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plus, Edit2, Trash2, Search, Loader2, Clock, CheckCircle } from 'lucide-react';
import { useBlogPosts } from '../../hooks/useBlogPosts';
import { useAuth } from '../../contexts/AuthContext';
import { format, isAfter } from 'date-fns';
import SEO from '../../components/SEO';

const Posts = () => {
  const { posts, loading, error } = useBlogPosts();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredPosts = posts.filter(post => 
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Function to determine post status
  const getPostStatus = (post: {
    published: boolean;
    scheduled_publish_date?: string | null;
  }) => {
    if (!post.published) {
      return {
        label: 'Draft',
        className: 'bg-yellow-100 text-yellow-800'
      };
    }
    
    if (post.scheduled_publish_date) {
      const scheduleDate = new Date(post.scheduled_publish_date);
      if (isAfter(scheduleDate, new Date())) {
        return {
          label: 'Scheduled',
          className: 'bg-purple-100 text-purple-800'
        };
      }
    }
    
    return {
      label: 'Published',
      className: 'bg-green-100 text-green-800'
    };
  };

  if (loading) {
    return (
      <div className="min-h-[calc(100vh-5rem)] flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-teal-500 mx-auto mb-4" />
          <p className="text-gray-600">
            Loading posts...
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[calc(100vh-5rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Error loading posts</h2>
          <p className="text-gray-600">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="Manage Blog Posts"
        description="Create, edit, and manage blog posts and case studies for Sightline Global."
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-bold">Blog Posts</h1>
            <Link to="/admin/posts/new" className="btn btn-primary">
              <Plus className="w-5 h-5 mr-2" />
              New Post
            </Link>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="mb-6 relative">
              <input
                type="text"
                placeholder="Search posts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-4 px-4">Title</th>
                    <th className="text-left py-4 px-4">Author</th>
                    <th className="text-left py-4 px-4">Status</th>
                    <th className="text-left py-4 px-4">Date</th>
                    <th className="text-right py-4 px-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPosts.map((post) => {
                    const status = getPostStatus(post);
                    const isScheduled = post.published && post.scheduled_publish_date && 
                      isAfter(new Date(post.scheduled_publish_date), new Date());
                    return (
                      <tr key={post.id} className="border-b hover:bg-gray-50">
                        <td className="py-4 px-4">
                          <div className="flex items-center">
                            <Link 
                              to={`/blog/${post.slug}`}
                              target="_blank"
                              className="text-navy-700 hover:text-teal-500 transition-colors font-medium"
                            >
                              {post.title}
                            </Link>
                          </div>
                        </td>
                        <td className="py-4 px-4">{post.author}</td>
                        <td className="py-4 px-4">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${status.className}`}>
                            {isScheduled && <Clock className="w-3 h-3 mr-1" />}
                            {status.label}
                          </span>
                          {isScheduled && (
                            <div className="text-xs text-gray-500 mt-1">
                              {format(new Date(post.scheduled_publish_date!), 'MMM d, yyyy h:mm a')}
                            </div>
                          )}
                        </td>
                        <td className="py-4 px-4">
                          {new Date(post.created_at).toLocaleDateString()}
                        </td>
                        <td className="py-4 px-4 text-right">
                          <div className="flex items-center justify-end space-x-3">
                            <Link
                              to={`/admin/posts/${post.id}/edit`}
                              className="text-teal-500 hover:text-teal-600 p-1"
                              title="Edit post"
                            >
                              <Edit2 className="w-5 h-5" />
                            </Link>
                            <button
                              onClick={() => {
                                // Handle delete
                              }}
                              className="text-red-500 hover:text-red-600 p-1"
                              title="Delete post"
                            >
                              <Trash2 className="w-5 h-5" />
                            </button>
                            <Link
                              to={`/blog/${post.slug}`}
                              target="_blank"
                              className="text-blue-500 hover:text-blue-600 p-1"
                              title="View post"
                            >
                              <CheckCircle className="w-5 h-5" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {filteredPosts.length === 0 && (
                    <tr>
                      <td colSpan={5} className="py-8 text-center text-gray-500">
                        No posts found matching your search.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Posts;