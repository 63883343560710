import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Bot, Loader2, X, AlertCircle, CheckCircle } from 'lucide-react';
import { generateBlogPost } from '../services/ai';

interface AIPostGeneratorProps {
  onGenerated: (generatedContent: {
    title: string;
    slug: string;
    content: string;
    excerpt: string;
    key_highlights?: string[];
    stats?: {
      [key: string]: {
        label: string;
        value: string;
      };
    };
  }) => void;
  onClose: () => void;
}

const AIPostGenerator: React.FC<AIPostGeneratorProps> = ({ onGenerated, onClose }) => {
  const [topic, setTopic] = useState('');
  const [tone, setTone] = useState<'professional' | 'conversational' | 'technical'>('professional');
  const [length, setLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [targetAudience, setTargetAudience] = useState('');
  const [includeStatistics, setIncludeStatistics] = useState(true);
  const [includeKeyHighlights, setIncludeKeyHighlights] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!topic.trim()) {
      setError('Please enter a topic');
      return;
    }
    
    setError(null);
    setGenerating(true);
    
    try {
      const generatedPost = await generateBlogPost({
        topic,
        tone,
        length,
        targetAudience,
        includeStatistics,
        includeKeyHighlights
      });
      
      onGenerated(generatedPost);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to generate content');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-hidden"
      >
        <div className="flex items-center justify-between bg-navy-700 text-white p-4">
          <div className="flex items-center">
            <Bot className="w-6 h-6 mr-2" />
            <h2 className="text-xl font-bold">AI Content Generator</h2>
          </div>
          <button
            onClick={onClose}
            className="text-silver-300 hover:text-white"
            disabled={generating}
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        
        <div className="p-6">
          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md flex items-center">
              <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Topic <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="e.g., AI in Healthcare, Digital Transformation, Cloud Security"
                disabled={generating}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tone
                </label>
                <select
                  value={tone}
                  onChange={(e) => setTone(e.target.value as any)}
                  disabled={generating}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                >
                  <option value="professional">Professional</option>
                  <option value="conversational">Conversational</option>
                  <option value="technical">Technical</option>
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Length
                </label>
                <select
                  value={length}
                  onChange={(e) => setLength(e.target.value as any)}
                  disabled={generating}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                >
                  <option value="short">Short (300-500 words)</option>
                  <option value="medium">Medium (800-1200 words)</option>
                  <option value="long">Long (1500+ words)</option>
                </select>
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Target Audience
              </label>
              <input
                type="text"
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
                placeholder="e.g., CIOs, Small Business Owners, Technology Enthusiasts"
                disabled={generating}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
            </div>
            
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="includeStatistics"
                  checked={includeStatistics}
                  onChange={(e) => setIncludeStatistics(e.target.checked)}
                  disabled={generating}
                  className="h-4 w-4 text-teal-500 focus:ring-teal-500 border-gray-300 rounded"
                />
                <label htmlFor="includeStatistics" className="ml-2 block text-sm text-gray-900">
                  Include statistics
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="includeKeyHighlights"
                  checked={includeKeyHighlights}
                  onChange={(e) => setIncludeKeyHighlights(e.target.checked)}
                  disabled={generating}
                  className="h-4 w-4 text-teal-500 focus:ring-teal-500 border-gray-300 rounded"
                />
                <label htmlFor="includeKeyHighlights" className="ml-2 block text-sm text-gray-900">
                  Include key highlights
                </label>
              </div>
            </div>
            
            <div className="pt-4">
              <button
                type="submit"
                disabled={generating || !topic.trim()}
                className={`w-full btn ${generating ? 'btn-secondary opacity-70' : 'btn-primary'}`}
              >
                {generating ? (
                  <div className="flex items-center justify-center">
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Generating content...
                  </div>
                ) : (
                  <>
                    <Bot className="w-5 h-5 mr-2" />
                    Generate Content
                  </>
                )}
              </button>
            </div>
          </form>
          
          <div className="mt-6 pt-6 border-t border-gray-200">
            <div className="flex items-start text-gray-500 text-sm">
              <CheckCircle className="w-5 h-5 text-teal-500 mr-2 flex-shrink-0" />
              <p>
                Our AI will generate high-quality content based on your inputs. The generated content will include a title, excerpt, and main content that you can further edit as needed.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AIPostGenerator;