import React from 'react';

interface YouTubeEmbedProps {
  url: string;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ url }) => {
  // Extract video ID from URL
  const getVideoId = (url: string): string | null => {
    const patterns = [
      /youtu\.be\/([^#\&\?]{11})/,  // youtu.be/{id}
      /\?v=([^#\&\?]{11})/,         // ?v={id}
      /embed\/([^#\&\?]{11})/,      // embed/{id}
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match) return match[1];
    }

    return null;
  };

  const videoId = getVideoId(url);
  if (!videoId) return null;

  return (
    <div className="relative w-full pt-[56.25%] rounded-lg overflow-hidden my-8">
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeEmbed;