import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search, Loader2, AlertCircle, CheckCircle, ExternalLink } from 'lucide-react';
import SEO from '../../components/SEO';

const API_KEY = 'AIzaSyAwUSl5JYGHTFoHnzgSexy5mmJbHEH2Q8o';

interface PageSpeedMetrics {
  id: string;
  cruxMetrics: {
    [key: string]: string;
  };
  lighthouseMetrics: {
    [key: string]: string;
  };
  performanceScore: number;
  accessibilityScore: number;
  bestPracticesScore: number;
  seoScore: number;
}

const PageSpeedInsights: React.FC = () => {
  const [url, setUrl] = useState('https://web.dev/');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<PageSpeedMetrics | null>(null);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const analyzeUrl = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!url) {
      setError('Please enter a URL to analyze');
      return;
    }
    
    setLoading(true);
    setError(null);
    setResults(null);
    
    try {
      // Create a script element for JSONP request to avoid CORS issues
      const callbackName = 'handlePageSpeedResults';
      const script = document.createElement('script');
      
      // Define the callback function in the global scope
      (window as any)[callbackName] = (data: any) => {
        // Clean up script tag
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        
        try {
          // Process the data
          const lighthouse = data.lighthouseResult;
          
          // Extract the metrics we need
          const metrics: PageSpeedMetrics = {
            id: data.id,
            cruxMetrics: {
              'First Contentful Paint': data.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS?.category || 'N/A',
              'Interaction to Next Paint': data.loadingExperience?.metrics?.INTERACTION_TO_NEXT_PAINT?.category || 'N/A',
            },
            lighthouseMetrics: {
              'First Contentful Paint': lighthouse?.audits['first-contentful-paint']?.displayValue || 'N/A',
              'Speed Index': lighthouse?.audits['speed-index']?.displayValue || 'N/A',
              'Largest Contentful Paint': lighthouse?.audits['largest-contentful-paint']?.displayValue || 'N/A',
              'Total Blocking Time': lighthouse?.audits['total-blocking-time']?.displayValue || 'N/A',
              'Time To Interactive': lighthouse?.audits['interactive']?.displayValue || 'N/A',
              'Cumulative Layout Shift': lighthouse?.audits['cumulative-layout-shift']?.displayValue || 'N/A'
            },
            performanceScore: Math.round((lighthouse?.categories?.performance?.score || 0) * 100),
            accessibilityScore: Math.round((lighthouse?.categories?.accessibility?.score || 0) * 100),
            bestPracticesScore: Math.round((lighthouse?.categories['best-practices']?.score || 0) * 100),
            seoScore: Math.round((lighthouse?.categories?.seo?.score || 0) * 100)
          };
          
          setResults(metrics);
        } catch (err) {
          console.error('Error processing PageSpeed Insights data:', err);
          setError('Error processing the PageSpeed Insights results. Please try again.');
        } finally {
          setLoading(false);
          // Clean up the callback
          delete (window as any)[callbackName];
        }
      };
      
      // Create the URL with callback parameter for JSONP
      const apiEndpoint = 'https://www.googleapis.com/pagespeedonline/v5/runPagespeed';
      const apiUrl = new URL(apiEndpoint);
      
      apiUrl.searchParams.set('url', url);
      apiUrl.searchParams.set('key', API_KEY);
      apiUrl.searchParams.set('strategy', 'mobile');
      apiUrl.searchParams.set('category', 'performance');
      apiUrl.searchParams.set('category', 'accessibility');
      apiUrl.searchParams.set('category', 'best-practices');
      apiUrl.searchParams.set('category', 'seo');
      apiUrl.searchParams.set('callback', callbackName);
      
      // Set a timeout to handle cases where the API might not respond
      const timeoutId = setTimeout(() => {
        setError('Request timed out. Please try again.');
        setLoading(false);
        // Clean up
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        delete (window as any)[callbackName];
      }, 30000); // 30 seconds timeout
      
      // Add event listeners for error handling
      script.onerror = () => {
        clearTimeout(timeoutId);
        setError('Failed to load PageSpeed Insights. Please try again.');
        setLoading(false);
        delete (window as any)[callbackName];
      };
      
      // Set the script source and append to document
      script.src = apiUrl.toString();
      document.head.appendChild(script);
      
    } catch (error) {
      console.error('Error setting up PageSpeed Insights request:', error);
      setError('Failed to set up the PageSpeed Insights request. Please try again.');
      setLoading(false);
    }
  };

  // Helper to determine score color
  const getScoreColor = (score: number) => {
    if (score >= 90) return 'text-green-500';
    if (score >= 50) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="PageSpeed Insights"
        description="Analyze website performance using Google PageSpeed Insights"
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="text-3xl font-bold mb-8">PageSpeed Insights</h1>
          
          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-xl font-bold mb-4">Analyze Website Performance</h2>
            <form onSubmit={analyzeUrl} className="space-y-4">
              <div className="relative">
                <input
                  type="url"
                  value={url}
                  onChange={handleUrlChange}
                  placeholder="Enter website URL (e.g., https://web.dev/)"
                  className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                  required
                />
                <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
              </div>
              
              <button
                type="submit"
                disabled={loading}
                className={`btn btn-primary ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    Analyzing...
                  </>
                ) : (
                  'Analyze Performance'
                )}
              </button>
            </form>
          </div>
          
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg flex items-start mb-8">
              <AlertCircle className="w-5 h-5 mr-3 mt-0.5 flex-shrink-0" />
              <div>
                <p className="font-medium">Error analyzing website</p>
                <p className="text-sm">{error}</p>
              </div>
            </div>
          )}
          
          {!loading && !error && !results && (
            <div className="bg-blue-50 border border-blue-200 text-blue-700 px-4 py-3 rounded-lg flex items-start mb-8">
              <div>
                <p className="font-medium">Sample URLs to try:</p>
                <ul className="list-disc list-inside mt-2 space-y-1">
                  <li>https://web.dev/</li>
                  <li>https://developer.mozilla.org/</li>
                  <li>https://reactjs.org/</li>
                </ul>
                <p className="mt-2 text-sm">For best results, analyze websites that are publicly accessible.</p>
              </div>
            </div>
          )}
          
          {results && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-8"
            >
              <div className="bg-white rounded-lg shadow-lg p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold">Results for {results.id}</h2>
                  <a 
                    href={`https://developers.google.com/speed/pagespeed/insights/?url=${encodeURIComponent(results.id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-teal-500 hover:text-teal-600 flex items-center"
                  >
                    View on PageSpeed Insights
                    <ExternalLink className="w-4 h-4 ml-1" />
                  </a>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
                  <div className="bg-gray-50 p-4 rounded-lg text-center">
                    <div className={`text-3xl font-bold ${getScoreColor(results.performanceScore)}`}>
                      {results.performanceScore}
                    </div>
                    <div className="text-sm text-gray-600">Performance</div>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg text-center">
                    <div className={`text-3xl font-bold ${getScoreColor(results.accessibilityScore)}`}>
                      {results.accessibilityScore}
                    </div>
                    <div className="text-sm text-gray-600">Accessibility</div>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg text-center">
                    <div className={`text-3xl font-bold ${getScoreColor(results.bestPracticesScore)}`}>
                      {results.bestPracticesScore}
                    </div>
                    <div className="text-sm text-gray-600">Best Practices</div>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg text-center">
                    <div className={`text-3xl font-bold ${getScoreColor(results.seoScore)}`}>
                      {results.seoScore}
                    </div>
                    <div className="text-sm text-gray-600">SEO</div>
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-lg font-semibold mb-4">Lighthouse Metrics</h3>
                    <div className="space-y-2">
                      {Object.entries(results.lighthouseMetrics).map(([key, value]) => (
                        <div key={key} className="flex justify-between border-b border-gray-100 py-2">
                          <span className="text-gray-700">{key}</span>
                          <span className="font-medium">{value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  <div>
                    <h3 className="text-lg font-semibold mb-4">Chrome User Experience Report</h3>
                    <div className="space-y-2">
                      {Object.entries(results.cruxMetrics).map(([key, value]) => (
                        <div key={key} className="flex justify-between border-b border-gray-100 py-2">
                          <span className="text-gray-700">{key}</span>
                          <span 
                            className={`font-medium ${
                              value === 'FAST' ? 'text-green-500' : 
                              value === 'AVERAGE' ? 'text-yellow-500' : 
                              value === 'SLOW' ? 'text-red-500' : ''
                            }`}
                          >
                            {value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-lg font-semibold mb-4">Performance Improvement Tips</h3>
                <ul className="space-y-2">
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                    <span>Optimize images with WebP format and responsive sizes</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                    <span>Implement lazy loading for below-the-fold content</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                    <span>Minimize render-blocking resources by deferring non-critical JavaScript</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                    <span>Enable text compression (Gzip, Brotli) on your server</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-0.5" />
                    <span>Use a content delivery network (CDN) to reduce server response times</span>
                  </li>
                </ul>
              </div>
            </motion.div>
          )}
        </motion.div>
      </div>
    </main>
  );
};

export default PageSpeedInsights;