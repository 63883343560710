import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar, Clock, ArrowRight, Bot } from 'lucide-react';
import { BlogPost } from '../services/blog';

const StatCard = ({ label, value }: { label: string; value: string }) => (
  <div className="text-center">
    <div className="text-2xl font-bold text-teal-500">{value}</div>
    <div className="text-sm text-gray-600">{label}</div>
  </div>
);

interface BlogCardProps {
  post: BlogPost;
}

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all"
    >
      <div className="relative h-64">
        <img
          src={post.image_url}
          alt={post.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 left-4 flex space-x-2">
          <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
            post.type === 'Case Study' ? 'bg-teal-500' : 'bg-purple-500'
          } text-white`}>
            <Bot className="w-4 h-4 mr-2" />
            {post.type}
          </span>
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-navy-700 text-white">
            {post.category}
          </span>
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center text-gray-500 text-sm mb-3">
          <Calendar className="w-4 h-4 mr-2" />
          {new Date(post.created_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
          <Clock className="w-4 h-4 ml-4 mr-2" />
          {post.read_time}
        </div>
        <h3 className="text-2xl font-bold mb-3">{post.title}</h3>
        <p className="text-gray-600 mb-4">{post.excerpt}</p>
        
        {post.stats && (
          <div className="grid grid-cols-3 gap-4 mb-6 py-4 border-y border-gray-100">
            {Object.entries(post.stats).map(([key, stat]) => (
              <StatCard key={key} label={stat.label} value={stat.value} />
            ))}
          </div>
        )}

        {post.key_highlights && (
          <div className="space-y-2 mb-6">
            <h4 className="font-semibold">Key Highlights:</h4>
            <ul className="space-y-1">
              {post.key_highlights.map((highlight, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <ArrowRight className="w-4 h-4 mr-2 text-teal-500" />
                  {highlight}
                </li>
              ))}
            </ul>
          </div>
        )}
        
        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center">
            <div className="text-sm">
              <p className="font-semibold text-gray-900">{post.author}</p>
              <p className="text-gray-600">{post.author_title}</p>
            </div>
          </div>
          <Link
            to={`/blog/${post.slug}`}
            className="inline-flex items-center text-teal-500 font-semibold hover:text-teal-600 transition-colors"
          >
            Read More
            <ArrowRight className="ml-2 w-4 h-4" />
          </Link>
        </div>
      </div>
    </motion.article>
  );
};

export default BlogCard;