import { supabase } from './supabase';
import { AuthError, User } from '@supabase/supabase-js';

export interface SignUpData {
  email: string;
  password: string;
}

export interface SignInData {
  email: string;
  password: string;
}

export const signUp = async ({ email, password }: SignUpData) => {
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${window.location.origin}/login`
      }
    });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Sign up error:', error);
    if (error instanceof AuthError) {
      switch (error.message) {
        case 'User already registered':
          throw new Error('An account with this email already exists');
        case 'Password should be at least 6 characters':
          throw new Error('Password must be at least 6 characters long');
        default:
          throw new Error('Failed to create account. Please try again.');
      }
    }
    throw error;
  }
};

export const signIn = async ({ email, password }: SignInData) => {
  try {
    // First, ensure we have a clean session state
    await supabase.auth.signOut();

    // Attempt to sign in
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) {
      console.error('Sign in error:', error);
      if (error.message.includes('Invalid login credentials')) {
        throw new Error('Invalid email or password. Please try again.');
      }
      throw error;
    }

    // Verify the session was created
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    
    if (sessionError) {
      console.error('Session error:', sessionError);
      throw sessionError;
    }

    if (!session) {
      throw new Error('Failed to establish session. Please try again.');
    }

    return data;
  } catch (error) {
    console.error('Authentication error:', error);
    if (error instanceof AuthError) {
      switch (error.message) {
        case 'Invalid login credentials':
          throw new Error('Invalid email or password. Please try again.');
        case 'Email not confirmed':
          throw new Error('Please verify your email before signing in');
        default:
          throw new Error('Failed to sign in. Please try again.');
      }
    }
    throw error;
  }
};

export const signOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Sign out error:', error);
    if (error instanceof AuthError) {
      throw new Error('Failed to sign out. Please try again.');
    }
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Reset password error:', error);
    if (error instanceof AuthError) {
      switch (error.message) {
        case 'Email not found':
          throw new Error('No account found with this email address');
        default:
          throw new Error('Failed to send reset password email. Please try again.');
      }
    }
    throw error;
  }
};

export const getCurrentSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    
    if (error) {
      throw error;
    }
    
    return session;
  } catch (error) {
    console.error('Get session error:', error);
    if (error instanceof AuthError) {
      throw new Error('Failed to get current session');
    }
    throw error;
  }
};

export const getCurrentUser = async () => {
  try {
    const session = await getCurrentSession();
    return session?.user ?? null;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
};

export const isAdmin = (user: User | null) => {
  if (!user) return false;
  return user.user_metadata?.role === 'admin';
};