import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plus, Edit2, Trash2, Loader2 } from 'lucide-react';
import { useBrands } from '../../hooks/useBrands';
import SEO from '../../components/SEO';

const Brands = () => {
  const { brands, loading, error } = useBrands();
  const [deleting, setDeleting] = useState<string | null>(null);

  const handleDelete = async (id: string) => {
    setDeleting(id);
    try {
      // TODO: Implement delete brand
    } catch (error) {
      console.error('Error deleting brand:', error);
    } finally {
      setDeleting(null);
    }
  };

  if (loading) {
    return (
      <div className="min-h-[calc(100vh-5rem)] flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-teal-500 mx-auto mb-4" />
          <p className="text-gray-600">Loading brands...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[calc(100vh-5rem)] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Error loading brands</h2>
          <p className="text-gray-600">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <main className="min-h-[calc(100vh-5rem)] bg-gray-50 py-12">
      <SEO
        title="Manage Brands"
        description="Create and manage brand portfolio for Sightline Global"
      />
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-3xl font-bold">Brands</h1>
            <Link to="/admin/brands/new" className="btn btn-primary">
              <Plus className="w-5 h-5 mr-2" />
              New Brand
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {brands.map((brand) => (
              <div
                key={brand.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <div className="h-48 relative">
                  <img
                    src={brand.image_url}
                    alt={brand.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-4 right-4 flex space-x-2">
                    <Link
                      to={`/admin/brands/${brand.id}/edit`}
                      className="bg-white p-2 rounded-full shadow-lg text-teal-500 hover:text-teal-600"
                    >
                      <Edit2 className="w-5 h-5" />
                    </Link>
                    <button
                      onClick={() => handleDelete(brand.id)}
                      disabled={deleting === brand.id}
                      className="bg-white p-2 rounded-full shadow-lg text-red-500 hover:text-red-600"
                    >
                      {deleting === brand.id ? (
                        <Loader2 className="w-5 h-5 animate-spin" />
                      ) : (
                        <Trash2 className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{brand.name}</h3>
                  <p className="text-gray-600">{brand.description}</p>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default Brands;