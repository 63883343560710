import { supabase } from '../lib/supabase';

export interface Service {
  id: string;
  title: string;
  description: string;
  icon: string;
  features: string[];
  created_at: string;
  updated_at: string;
}

const defaultServices: Service[] = [
  {
    id: '1',
    title: 'AI-Driven Automation',
    description: 'Streamline your operations with intelligent automation solutions powered by cutting-edge AI technology.',
    icon: 'Bot',
    features: [
      'Process Automation',
      'Machine Learning Integration',
      'Intelligent Analytics',
      'Predictive Modeling'
    ],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '2',
    title: 'Digital Transformation',
    description: 'Transform your business with comprehensive digital solutions that drive growth and efficiency.',
    icon: 'Rocket',
    features: [
      'Technology Strategy',
      'Cloud Migration',
      'Digital Workflow Optimization',
      'System Integration'
    ],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '3',
    title: 'Strategic Growth',
    description: 'Accelerate your business growth with data-driven strategies and expert consultation.',
    icon: 'LineChart',
    features: [
      'Market Analysis',
      'Growth Strategy',
      'Performance Optimization',
      'Business Intelligence'
    ],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  },
  {
    id: '4',
    title: 'Content Publishing',
    description: 'Enhance your digital presence with strategic content creation and distribution solutions.',
    icon: 'FileText',
    features: [
      'Content Strategy',
      'Automated Publishing',
      'Analytics & Optimization',
      'Multi-channel Distribution'
    ],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString()
  }
];

export const getServices = async (): Promise<Service[]> => {
  try {
    const { data, error } = await supabase
      .from('services')
      .select('*')
      .order('created_at', { ascending: true });

    if (error) {
      console.error('Error fetching services:', error);
      // Return default services if database fetch fails
      return defaultServices;
    }

    return data?.length ? data : defaultServices;
  } catch (error) {
    console.error('Error in getServices:', error);
    // Return default services if there's an error
    return defaultServices;
  }
};