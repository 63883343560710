import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import ServicesPage from './pages/Services';
import BrandsPage from './pages/Brands';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Login from './pages/Login';
import Dashboard from './pages/admin/Dashboard';
import Posts from './pages/admin/Posts';
import PostEditor from './pages/admin/PostEditor';
import AdminServices from './pages/admin/Services';
import ServiceEditor from './pages/admin/ServiceEditor';
import AdminBrands from './pages/admin/Brands';
import BrandEditor from './pages/admin/BrandEditor';
import Analytics from './pages/admin/Analytics';
import SEODashboard from './pages/admin/SEODashboard';
import PageSpeedInsights from './pages/admin/PageSpeedInsights';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <ScrollToTop>
            <div className="min-h-screen flex flex-col">
              <Navbar />
              <AnimatePresence mode="wait">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/brands" element={<BrandsPage />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/login" element={<Login />} />

                  {/* Admin Routes */}
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute requireAdmin>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/posts"
                    element={
                      <ProtectedRoute requireAdmin>
                        <Posts />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/posts/new"
                    element={
                      <ProtectedRoute requireAdmin>
                        <PostEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/posts/:id/edit"
                    element={
                      <ProtectedRoute requireAdmin>
                        <PostEditor />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/services/*"
                    element={
                      <ProtectedRoute requireAdmin>
                        <AdminServices />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/brands/*"
                    element={
                      <ProtectedRoute requireAdmin>
                        <AdminBrands />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/analytics"
                    element={
                      <ProtectedRoute requireAdmin>
                        <Analytics />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/seo"
                    element={
                      <ProtectedRoute requireAdmin>
                        <SEODashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/pagespeed"
                    element={
                      <ProtectedRoute requireAdmin>
                        <PageSpeedInsights />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </AnimatePresence>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;