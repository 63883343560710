import { supabase } from '../lib/supabase';

export interface ContactSubmission {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  message: string;
  status?: string;
  created_at?: string;
}

export const submitContactForm = async (submission: ContactSubmission): Promise<void> => {
  const { error } = await supabase
    .from('contact_submissions')
    .insert([submission]);

  if (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};