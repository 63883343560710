import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, LogOut, Settings } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { isAdmin } from '../lib/auth';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const userIsAdmin = user && isAdmin(user);

  // Define base navigation items
  const baseNavigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Services', href: '/services' },
    { name: 'Our Brands', href: '/brands' },
    // News & Insights tab is conditional
    { name: 'Contact', href: '/contact' },
  ];

  // Add News & Insights tab only for admin users
  const navigation = userIsAdmin 
    ? [...baseNavigation.slice(0, 4), { name: 'News & Insights', href: '/blog' }, ...baseNavigation.slice(4)]
    : baseNavigation;

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="container">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex items-center space-x-2">
            <img 
              src="https://ijqrexobtumuxnqihpdl.supabase.co/storage/v1/object/sign/website-images/Global%202025.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWJzaXRlLWltYWdlcy9HbG9iYWwgMjAyNS5wbmciLCJpYXQiOjE3NDE3MzE3MjMsImV4cCI6MjAyNTU1NTcyM30.LCrwXM9nsgfCtyn6KI5z3zdJQRWQoZx1N_6RV7cSaF0" 
              alt="Sightline Global" 
              className="w-10 h-10" 
            />
            <span className="text-xl font-poppins font-bold text-navy-700">
              Sightline Global
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`nav-link ${
                  location.pathname === item.href ? 'text-teal-500' : ''
                }`}
              >
                {item.name}
              </Link>
            ))}
            {user ? (
              <div className="flex items-center space-x-4">
                {userIsAdmin && (
                  <Link
                    to="/admin"
                    className="flex items-center text-gray-700 hover:text-teal-500 transition-colors"
                  >
                    <Settings className="w-4 h-4 mr-2" />
                    Admin
                  </Link>
                )}
                <button
                  onClick={handleSignOut}
                  className="flex items-center text-gray-700 hover:text-teal-500 transition-colors"
                >
                  <LogOut className="w-4 h-4 mr-2" />
                  Sign Out
                </button>
              </div>
            ) : (
              <Link
                to="/login"
                className="btn btn-secondary"
              >
                Admin Login
              </Link>
            )}
          </div>

          {/* Mobile Navigation Button */}
          <button
            className="md:hidden"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <X className="w-6 h-6 text-navy-700" />
            ) : (
              <Menu className="w-6 h-6 text-navy-700" />
            )}
          </button>
        </div>

        {/* Mobile Navigation Menu */}
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="md:hidden py-4"
          >
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="block py-2 nav-link"
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </Link>
            ))}
            {user ? (
              <>
                {userIsAdmin && (
                  <Link
                    to="/admin"
                    className="block py-2 nav-link"
                    onClick={() => setIsOpen(false)}
                  >
                    <Settings className="w-4 h-4 inline mr-2" />
                    Admin
                  </Link>
                )}
                <button
                  onClick={() => {
                    handleSignOut();
                    setIsOpen(false);
                  }}
                  className="block w-full text-left py-2 nav-link"
                >
                  <LogOut className="w-4 h-4 inline mr-2" />
                  Sign Out
                </button>
              </>
            ) : (
              <Link
                to="/login"
                className="block py-2 nav-link"
                onClick={() => setIsOpen(false)}
              >
                Admin Login
              </Link>
            )}
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;